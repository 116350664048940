/*
 *
 *  *
 *  *  Copyright (C) THL A29 Limited, a Tencent company. All rights reserved.
 *  *  SPDX-License-Identifier: Apache-2.0
 *  *
 *
 */

import React, { useEffect, useState } from 'react';
import style from './index.module.scss';
import Between from '@components/ui/Between';
import { Link, useParams } from 'react-router-dom';
import { GetBlockList } from '@src/utils/apis';
import { BlockItem } from '@src/models';
import { formatUnixWithoutYear } from '@src/utils/tools';
import { Bubble } from 'tea-component';
export default function NewBlocks() {
  const { chainId } = useParams();
  const [list] = useState([0, 1, 2, 3]);
  const [blocks, setBlocks] = useState<BlockItem[]>([]);
  useEffect(() => {
    GetBlockList({
      ChainId: chainId,
      Limit: 4,
    }).then((res) => {
      if (res.GroupList) {
        setBlocks(res.GroupList);
      }
    });
  }, [chainId]);
  return (
    <div className={style.block_c}>
      <Between
        title="最新区块"
        content={
          <Link className={style.block_more} to={`/${chainId}/block`}>
            更多&gt;
          </Link>
        }
      />
      <div className={style.block_steps}>
        {list?.map((item, index) => {
          const block = blocks[index];
          return block ? (
            <Link
              to={`/${chainId}/block/${block.BlockHeight}`}
              key={index}
              className={`${index !== 0 ? style.block_step_triangle : ''} ${style.block_step_i}`}
            >
              <div className={style.block_line}>
                <div className={style.block_title}>{block.BlockHeight}</div>
                <div className={style.block_time}>{formatUnixWithoutYear(block.Timestamp)}</div>
              </div>
              <div className={style.block_line}>
                <div className={style.block_subtitle}>{block.TxCount} Txns</div>
                <div className={`${style.block_path} single_line`}>
                  <Bubble content={block.ProposalNodeId}>{block.ProposalNodeId}</Bubble>
                </div>
              </div>
            </Link>
          ) : (
            <div key={index} className={`${index !== 0 ? style.block_step_triangle : ''} ${style.block_step_i}`}></div>
          );
        })}
      </div>
    </div>
  );
}
