/*
 *
 *  *
 *  *  Copyright (C) THL A29 Limited, a Tencent company. All rights reserved.
 *  *  SPDX-License-Identifier: Apache-2.0
 *  *
 *
 */

import React, { useCallback, useEffect, useMemo, useState } from 'react';
import liststyle from '../list.module.scss';
import PageBox from '@src/components/ui/PageBox';
import { Button, Text, Justify, Table, TableColumn } from 'tea-component';
import SearchInput from '@components/ui/SearchInput';
import SubscribeFormModel from './components/SubscribeFormModel';
import ConfirmModel from '@components/ui/ConfirmModel';
import { useParams } from 'react-router-dom';
import { GetChainList, SubscribeChain, ModifySubscribe, CancelSubscribe } from '@src/utils/apis';
import { ChainItem, Subscribe } from '@src/models';
import { formatUnixTime } from '@src/utils/tools';
import { useDispatch } from 'react-redux';
const { pageable, autotip } = Table.addons;

export default function Chain() {
  const dispatch = useDispatch();
  const { chainId } = useParams();
  const [list, setList] = useState<ChainItem[]>([]);
  const [value, setValue] = useState('');
  const [subTitle, setSubTitle] = useState('');
  const [visible, setVisible] = useState(false);
  const [initialData, setInitiaData] = useState('');
  const [pageIndex, setPageIndex] = useState<number>(1);
  const [pageSize, setPageSize] = useState<number>(10);
  const [recordCount, setRecordCount] = useState(0);
  const [confirmVisible, setConfirmVisible] = useState<boolean>(false);
  const [editChainId, setEditChainId] = useState<string>('');
  const [isLoading, setIsLoading] = useState(false);
  const updateChainSelect = useCallback(() => {
    dispatch({
      type: 'GET_CHAINS',
      payload: {
        Limit: 99999,
      },
    });
  }, []);
  const getList = useCallback(() => {
    setIsLoading(true);
    GetChainList({
      ChainId: value,
      Offset: pageIndex - 1,
      Limit: pageSize,
    })
      .then((res) => {
        setIsLoading(false);
        if (res.GroupList) {
          setList(res.GroupList);
          setRecordCount(res.TotalCount);
        }
      })
      .catch(() => {
        setIsLoading(false);
      });
  }, [chainId, value, pageIndex, pageSize]);
  const openAdd = useCallback(() => {
    setSubTitle('新增订阅链');
    setInitiaData('');
    setVisible(true);
  }, []);
  const openEdit = useCallback((Id, isReEdit?: boolean) => {
    setSubTitle(isReEdit ? '重新订阅链信息' : '修改订阅链信息');
    setInitiaData(Id);
    setVisible(true);
  }, []);
  const onSubmit = useCallback(
    (subscribe: Subscribe) => {
      const subapi = initialData ? ModifySubscribe : SubscribeChain;
      subapi(subscribe).then((res: any) => {
        if (!res.Error) {
          getList();
          updateChainSelect();
          setVisible(false);
        }
      });
    },
    [initialData],
  );
  const openCancelConfirm = useCallback((Id) => {
    setConfirmVisible(true);
    setEditChainId(Id);
  }, []);
  const subCancelSubscribe = useCallback(() => {
    CancelSubscribe({
      ChainId: editChainId,
    }).then((res: any) => {
      setConfirmVisible(false);
      if (!res.Error) {
        getList();
        // updateChainSelect();
      }
    });
  }, [editChainId]);
  useEffect(() => {
    getList();
  }, [pageIndex, chainId, pageSize]);
  const columns = useMemo<TableColumn[]>(
    () => [
      {
        key: 'ChainId',
        header: '区块链id',
        align: 'left',
      },
      {
        key: 'ChainVersion',
        header: '链版本',
        align: 'left',
      },
      {
        key: 'Consensus',
        header: '共识算法',
        align: 'left',
      },
      {
        key: 'Timestamp',
        header: '创建时间',
        align: 'left',
        render: ({ Timestamp }) => formatUnixTime(Timestamp),
      },
      {
        key: 'Status',
        header: '状态',
        align: 'left',
        render: ({ Status }) => (Status === 0 ? <Text theme="success">正常</Text> : <Text theme="danger">异常</Text>),
      },
      {
        key: 'Id',
        header: '操作',
        align: 'center',
        render: ({ ChainId, Status }) => (
          <div className={liststyle.setting_c}>
            {Status === 2 ? (
              <Text theme="primary" onClick={() => openEdit(ChainId, true)}>
                重新订阅
              </Text>
            ) : (
              <Text theme="primary" onClick={() => openEdit(ChainId)}>
                修改
              </Text>
            )}
            {Status !== 2 && (
              <>
                <div className={liststyle.line}></div>
                <Text theme="warning" onClick={() => openCancelConfirm(ChainId)}>
                  取消订阅
                </Text>
              </>
            )}
          </div>
        ),
      },
    ],
    [],
  );
  return (
    <PageBox title="区块链列表">
      <Justify
        left={
          <Button style={{ height: 40, width: 115, fontSize: 14 }} onClick={() => openAdd()} type="primary">
            新增订阅
          </Button>
        }
        right={
          <SearchInput
            placeholder="请输入链Id搜索"
            onChange={(value) => setValue(value)}
            onSubmit={getList}
            value={value}
          />
        }
      />
      <div className={liststyle.table}>
        <Table
          style={{
            marginTop: 24,
          }}
          className={liststyle.table}
          compact={false}
          records={list}
          recordKey="Id"
          bordered={true}
          disableTextOverflow={true}
          columns={columns}
          addons={[
            autotip({
              isLoading,
            }),
            pageable({
              recordCount,
              pageIndex,
              onPagingChange: (query) => {
                if (query?.pageIndex) {
                  setPageIndex(query.pageIndex);
                }
                if (query?.pageSize) {
                  setPageSize(query.pageSize);
                }
              },
            }),
          ]}
        />
      </div>
      <SubscribeFormModel
        visible={visible}
        onSubmit={onSubmit}
        onClose={() => setVisible(false)}
        initialData={initialData}
        title={subTitle}
      />
      <ConfirmModel
        visible={confirmVisible}
        onSubmit={subCancelSubscribe}
        onClose={() => setConfirmVisible(false)}
        title="取消订阅确认"
        message={`确定要取消订阅${editChainId}吗？取消订阅后，浏览器将无法再获取到该链的信息，且将清空原有数据，如后续还想获得信息，需要重新订阅，请确定是否取消订阅。`}
        submitText="取消订阅"
        cancelText="我在想想"
      />
    </PageBox>
  );
}
