/*
 *
 *  *
 *  *  Copyright (C) THL A29 Limited, a Tencent company. All rights reserved.
 *  *  SPDX-License-Identifier: Apache-2.0
 *  *
 *
 */

import React, { useCallback, useEffect, useMemo, useState } from 'react';
import liststyle from '../list.module.scss';
import PageBox from '@src/components/ui/PageBox';
import { Justify, Table, TableColumn } from 'tea-component';
import SearchInput from '@components/ui/SearchInput';
import { GetOrgList } from '@src/utils/apis';
import { OriginItem } from '@src/models';
import { useParams } from 'react-router-dom';
// import { formatUnixTime } from '@src/utils/tools';
const { pageable, autotip } = Table.addons;

export default function Chain() {
  const { chainId } = useParams();
  const [list, setList] = useState<OriginItem[]>([]);
  const [value, setValue] = useState('');
  const [pageIndex, setPageIndex] = useState<number>(1);
  const [pageSize, setPageSize] = useState<number>(10);
  const [recordCount, setRecordCount] = useState(0);
  const [isLoading, setIsLoading] = useState(false);
  const getList = useCallback(() => {
    setIsLoading(true);
    GetOrgList({
      ChainId: chainId,
      Offset: pageIndex - 1,
      Limit: pageSize,
      OrgId: value,
    })
      .then((res) => {
        setIsLoading(false);
        if (res.GroupList) {
          setList(res.GroupList);
          setRecordCount(res.TotalCount);
        }
      })
      .catch(() => {
        setIsLoading(false);
      });
  }, [chainId, value, pageIndex, pageSize]);
  useEffect(() => {
    getList();
  }, [pageIndex, chainId, pageSize]);
  const columns = useMemo<TableColumn[]>(
    () => [
      {
        key: 'OrgId',
        header: '组织Id',
        align: 'left',
      },
      {
        key: 'NodeCount',
        header: '节点数',
        align: 'left',
      },
      {
        key: 'UserCount',
        header: '用户数',
        align: 'left',
      },
      // {
      //   key: 'Timestamp',
      //   header: '创建时间',
      //   align: 'left',
      //   render: ({ Timestamp }) => <Text theme="label">{formatUnixTime(Timestamp)}</Text>,
      // },
    ],
    [],
  );
  return (
    <PageBox title="组织列表">
      <Justify
        right={
          <SearchInput
            placeholder="请输入组织名称搜索"
            onChange={(value) => setValue(value)}
            onSubmit={getList}
            value={value}
          />
        }
      />
      <div className={liststyle.table}>
        <Table
          style={{
            marginTop: 24,
          }}
          className={liststyle.table}
          compact={false}
          records={list}
          recordKey="OrgId"
          bordered={true}
          disableTextOverflow={true}
          columns={columns}
          addons={[
            autotip({
              isLoading,
            }),
            pageable({
              recordCount,
              pageIndex,
              onPagingChange: (query) => {
                if (query?.pageIndex) {
                  setPageIndex(query.pageIndex);
                }
                if (query?.pageSize) {
                  setPageSize(query.pageSize);
                }
              },
            }),
          ]}
        />
      </div>
    </PageBox>
  );
}
