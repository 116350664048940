/*
 *
 *  *
 *  *  Copyright (C) THL A29 Limited, a Tencent company. All rights reserved.
 *  *  SPDX-License-Identifier: Apache-2.0
 *  *
 *
 */

export const transformBase64ToStr = (base: string): string => {
  try {
    const decode = (atob(base));
    const str = decodeURIComponent(escape(decode));
    return str;
  } catch (e) {
    return base;
  }
};
