/*
 *
 *  *
 *  *  Copyright (C) THL A29 Limited, a Tencent company. All rights reserved.
 *  *  SPDX-License-Identifier: Apache-2.0
 *  *
 *
 */

import React, { useCallback, useEffect, useMemo, useState } from 'react';
import liststyle from '../../list.module.scss';
import { Table, TableColumn } from 'tea-component';
import { EventItem } from '@src/models';
import { GetEventList } from '@src/utils/apis';
import { useParams } from 'react-router-dom';
import { formatUnixTime } from '@src/utils/tools';
const { pageable, autotip } = Table.addons;

export default function Event() {
  const { chainId, contractName } = useParams();
  const [pageIndex, setPageIndex] = useState<number>(1);
  const [pageSize, setPageSize] = useState<number>(10);
  const [recordCount, setRecordCount] = useState<number>(0);
  const [isLoading, setIsLoading] = useState(false);
  const [list, setList] = useState<EventItem[]>([]);
  const getList = useCallback(() => {
    setIsLoading(true);
    GetEventList({
      Offset: pageIndex - 1,
      Limit: 10,
      ChainId: chainId,
      ContractName: contractName,
    })
      .then((res) => {
        setIsLoading(false);
        if (res.GroupList) {
          setList(res.GroupList);
          setRecordCount(res.TotalCount);
        }
      })
      .catch(() => {
        setIsLoading(false);
      });
  }, [chainId, pageIndex, pageSize, contractName]);
  useEffect(() => {
    getList();
  }, [chainId, pageIndex, pageSize]);
  const columns = useMemo<TableColumn[]>(
    () => [
      {
        key: 'Timestamp',
        header: '事件时间',
        align: 'left',
        render: ({ Timestamp }) => formatUnixTime(Timestamp),
      },
      {
        key: 'Topic',
        header: '事件主题',
        align: 'left',
      },
      {
        key: 'EventData',
        header: '事件内容',
        align: 'left',
      },
    ],
    [],
  );
  return (
    <div className={liststyle.table}>
      <Table
        style={{
          marginTop: 24,
        }}
        className={liststyle.table}
        compact={false}
        records={list}
        recordKey="EventInfo"
        bordered={true}
        disableTextOverflow={true}
        columns={columns}
        addons={[
          autotip({
            isLoading,
          }),
          pageable({
            recordCount,
            pageIndex,
            onPagingChange: (query) => {
              if (query?.pageIndex) {
                setPageIndex(query.pageIndex);
              }
              if (query?.pageSize) {
                setPageSize(query.pageSize);
              }
            },
          }),
        ]}
      />
    </div>
  );
}
