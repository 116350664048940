/*
 *
 *  *
 *  *  Copyright (C) THL A29 Limited, a Tencent company. All rights reserved.
 *  *  SPDX-License-Identifier: Apache-2.0
 *  *
 *
 */

import { ChainItem, ChainListParam } from '@src/models';
import { GetChainList } from '@src/utils/apis';
import { ResponseList } from '@src/utils/apis/type';
import { call, put, takeEvery } from 'redux-saga/effects';

function* getChains(action: { type: string; payload: ChainListParam }) {
  const data: ResponseList<ChainItem> = yield call(GetChainList, action.payload);
  try {
    yield put({
      type: 'UPDATE_CHAINS',
      payload: data.GroupList,
    });
  } catch (e) {}
}

export const CHAINSAGA = [takeEvery('GET_CHAINS', getChains)];
