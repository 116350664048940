/*
 *
 *  *
 *  *  Copyright (C) THL A29 Limited, a Tencent company. All rights reserved.
 *  *  SPDX-License-Identifier: Apache-2.0
 *  *
 *
 */

import React from 'react';
import { Button, Modal } from 'tea-component';
import style from './index.module.scss';

export default function ConfirmModel({
  title,
  message,
  visible,
  onSubmit,
  onClose,
  submitText,
  cancelText,
}: {
  title: string;
  message?: string;
  visible: boolean;
  onSubmit: () => void;
  onClose?: () => void;
  submitText?: string;
  cancelText?: string;
}) {
  // const [caption, setCaption] = useState('');
  return (
    <Modal
      size={440}
      className={style.confirm}
      visible={visible}
      caption={title}
      onClose={onClose}
      disableCloseIcon={true}
    >
      {message && <Modal.Body>{message}</Modal.Body>}
      <Modal.Footer>
        <Button type="weak" onClick={onClose}>
          {cancelText || '取消'}
        </Button>
        <Button type="primary" onClick={onSubmit}>
          {submitText || '确定'}
        </Button>
      </Modal.Footer>
    </Modal>
  );
}
