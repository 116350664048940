/*
 *
 *  *
 *  *  Copyright (C) THL A29 Limited, a Tencent company. All rights reserved.
 *  *  SPDX-License-Identifier: Apache-2.0
 *  *
 *
 */

import React, { useEffect, useState } from 'react';
import style from './index.module.scss';
import Between from '@components/ui/Between';
import { Link, useParams } from 'react-router-dom';
import { GetContractList } from '@src/utils/apis';
import { ContractItem } from '@src/models';
import { formatUnixWithoutYear } from '@src/utils/tools';

export default function Contracts() {
  const { chainId } = useParams();
  const [contracts, setContracts] = useState<ContractItem[]>([]);
  useEffect(() => {
    GetContractList({
      ChainId: chainId,
      Limit: 10,
      Order: 'desc',
    }).then((res) => {
      if (res.GroupList) {
        setContracts(res.GroupList);
      }
    });
  }, [chainId]);

  return (
    <div className={style.box}>
      <Between
        title="最新合约"
        content={
          <Link className={style.more} to={`/${chainId}/contract`}>
            更多&gt;
          </Link>
        }
      />
      <div className={style.list}>
        {contracts.length !== 0 &&
          contracts.map(({ Id, Name, Version, CreateTimestamp, Creator, TxCount }) => (
            <div key={Id} className={style.item}>
              <div className={style.info}>
                <div className={style.left}>
                  <div className={style.title}>
                    <Link to={`/${chainId}/contract/${Name}`}>{Name}</Link>
                  </div>
                  <div className={style.version}>{Version}</div>
                </div>
                <div className={style.time}>{formatUnixWithoutYear(CreateTimestamp)}</div>
              </div>
              <div className={style.info}>
                <div className={style.path}>{Creator}</div>
                <div className={style.name}>{TxCount} Txns</div>
              </div>
            </div>
          ))}
        {contracts.length === 0 && <div className="empty_list"></div>}
      </div>
    </div>
  );
}
