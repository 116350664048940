/*
 *
 *  *
 *  *  Copyright (C) THL A29 Limited, a Tencent company. All rights reserved.
 *  *  SPDX-License-Identifier: Apache-2.0
 *  *
 *
 */

import React, { useCallback } from 'react';
import style from './index.module.scss';
import { useNavigate } from 'react-router-dom';

export default function PageBox({ title, children }: { title: string; children: React.ReactNode }) {
  const navigate = useNavigate();
  const navBack = useCallback(() => {
    const { history } = window;
    if (history.length > 1) {
      history.back();
    } else {
      navigate('/');
    }
  }, []);
  return (
    <div className={style.box}>
      <div className={style.back}>
        <div className={style.left} onClick={navBack}></div>
        {title}
      </div>
      {children}
    </div>
  );
}
